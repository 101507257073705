
import "./../Style/OldArchive.css";
import "video-react/dist/video-react.css";

import ArchiveSlider from "../Components/ArchiveSlider";
import { useTranslation } from "react-i18next";
import {Player} from "video-react";
import recentArchiveVideo from "../Video/recentArchiveVideo.mp4";
import React from "react";

const RecentArchive = () => {
  const { t } = useTranslation();

  return (
      <div className="archive">
        <h4>{t("recent-archive-title")}</h4>
        <p>{t("recent-archive-introduction")}</p>
        <p>{t("recent-archive-description")}</p>
        <p>
          <h5>{t("recent-judges-title")}</h5>
          <ul>
            <li>{t("recent-judges-1")}</li>
            <li>{t("recent-judges-2")}</li>
            <li>{t("recent-judges-3")}</li>
            <li>{t("recent-judges-4")}</li>
            <li>{t("recent-judges-5")}</li>
            <li>{t("recent-judges-6")}</li>
          </ul>
        </p>
        <p>
          <h5 className="archive-title">{t("recent-winners-title")}</h5>
          <ul>
            <li>{t("recent-winners-1")}</li>
            <li>{t("recent-winners-2")}</li>
            <li>{t("recent-winners-3")}</li>
          </ul>
        </p>
        <p>
          <h5 className="archive-title">{t("recent-nominees-title")}</h5>
          <ul>
            <li>{t("recent-nominees-1")}</li>
            <li>{t("recent-nominees-2")}</li>
            <li>{t("recent-nominees-3")}</li>
            <li>{t("recent-nominees-4")}</li>
            <li>{t("recent-nominees-5")}</li>
            <li>{t("recent-nominees-6")}</li>
          </ul>
        </p>
        <p>{t("recent-archive-tip")}</p>
        <ArchiveSlider />
        <Player playsInline poster="/assets/poster.png" src={recentArchiveVideo} />
      </div>
  );
};

export default RecentArchive;
